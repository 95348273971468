@import '../../../../assets/stylesheets/ava/grid.scss';

.shared-ui--modal-ui--modal {
  z-index: 1005;
  .ava-modal {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    background: white;
    z-index: 1001;
    padding: 0 1em;
    max-height: 90vh;

    &.small {
      min-width: 30vw;
    }

    &.small-medium {
      min-width: 45vw;
    }
  
    &.medium {
      min-width: 60vw;
      overflow-y: auto;
    }

    &.large {
      min-width: 90vw;
      overflow-y: auto;
    }

    @media only screen and (max-width: $breakpoint-med) {
      &.small, &.small-medium, &.medium, &.large {
        min-width: 100vw;
        max-height: 100vh;
      }
    }

    @media only screen and (max-height: $height-breakpoint-med) {
      &.small,
      &.small-medium,
      &.medium,
      &.large {
        top: 10vh;
        transform: translate(-50%, 0%);
      }

      max-height: 80vh;
    }

    @media only screen and (max-height: $height-breakpoint-small) {
      &.small,
      &.small-medium,
      &.medium,
      &.large {
        top: 5vh;
        transform: translate(-50%, 0%);
      }
      max-height: 90vh;
    }
  
    .modal-title-container {
      z-index: 1002;
      position: sticky;
      top: 0;
      padding: 1em 0;
      background: white;
    
      > :first-child {
        // Top row with title and close button
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1em;
        
        > span:first-child {
          flex: 1;
          margin: 1em;
        }
        
        > div:nth-child(2) {
          // The close button
          position: relative;
          top: -2px;
          margin: 0 1em 0 1em;
          color: black;
          cursor: pointer;
          
          &::before {
            content: "\00D7";
            font-size: 2em;
          }
        }
        
        > div:nth-child(3) {
          // Subheader div
          width: 100%;
          margin-top: 0.5em;
          padding: 0 1em 1em 1em;
        }
      }
    }
    
    .turbo-modal-content {
      padding: 0 1em 1em 1em;
    }
  }

  .ava-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(10, 10, 10, 0.45);
    z-index: 1000;
  }
}